import type { ComponentType } from "react"
import { createStore } from "https://framer.com/m/framer/store.js@^1.0.0"
import { AnimatePresence } from "framer-motion"

const useStore = createStore({
    activeTags: [],
})

// TODO: Store tags using setStore() when the user clicks various filter tags in the UI
// TODO: Perhaps start with only one filter option, for example "Protocol", and later add the other
export function withActiveTag(Component): ComponentType {
    return (props) => {
        const [store, setStore] = useStore()
        const isActive = store.activeTags.includes(props.label)

        return (
            <Component
                {...props}
                variant={isActive ? "Active" : "Inactive"}
                onClick={() => {
                    if (isActive) {
                        setStore({
                            activeTags: store.activeTags.filter(
                                (item) => item !== props.label
                            ),
                        })
                    } else {
                        setStore({
                            activeTags: [...store.activeTags, props.label],
                        })
                    }
                }}
            />
        )
    }
}

// TODO: Get integrations from CMS, filter only items that have the stored tags as labels, and present updated list
// TODO: Perhaps start with only one filter option, for example "Protocol", and later add the other
export function withIntegrationFiltering(Component): ComponentType {
    return (props) => {
        const [store, setStore] = useStore()
        const tags = props.tags.split(",").map((t) => t.trim())
        const isVisible =
            store.activeTags.some((item) => tags.includes(item)) ||
            store.activeTags.length === 0

        console.log("tags: " + tags)
        console.log("isVisible: " + isVisible)
        return (
            <AnimatePresence initial={false}>
                {isVisible && (
                    <Component
                        {...props}
                        initial={{ opacity: 0, x: 20 }}
                        animate={{ opacity: 1, x: 0 }}
                        exit={{ opacity: 0, x: -20 }}
                    />
                )}
            </AnimatePresence>
        )
    }
}
